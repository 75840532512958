import {
    EuiBadge,
    EuiCode,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiTextTruncate,
    useEuiBackgroundColor,
} from '@elastic/eui'

import { AlarmCellProps } from '.'
import { FC } from 'react'
import { formatNumber } from '@utils/numbers'
import { isMultiValueType } from '@services/alarms/alarms.service'
import { parseAlarmOptionRange } from '@services/options'
import { parseServiceAlarmValue } from '@services/options/options.service'
import { useAppSelector } from '@hooks/store'
import { useTranslation } from 'react-i18next'

const AlarmTypeCell: FC<AlarmCellProps> = ({ value, record }) => {
    const { t } = useTranslation(['common', 'alarms'])
    const { prefrences } = useAppSelector((state) => state.alarms)

    // UI & Colors
    const bgDangerColor = useEuiBackgroundColor('danger')
    const bgWarningColor = useEuiBackgroundColor('warning')
    const bgDefaultColor = useEuiBackgroundColor('subdued')
    const bgPrimaryColor = useEuiBackgroundColor('primary')

    const formatValue = (value: string): string => {
        if (isMultiValueType(record.type)) {
            const arr = value.split(';')
            return arr
                .map((x) =>
                    !isNaN(parseFloat(x))
                        ? t(
                              `alarms:type_${parseServiceAlarmValue(record.type, x)}`
                          )
                        : t(`alarms:type_${x}`)
                )
                .join(' / ')
        }

        return formatNumber(value)
    }

    const formatValueRange = (value: string): string => {
        if (isMultiValueType(record.type)) return formatValue(value)
        const range = parseAlarmOptionRange(value)
        if (range) return `${range[0]} - ${range[1]}`
        return '?'
    }

    return (
        <EuiFlexGroup direction="column" gutterSize="xs">
            <EuiFlexItem>
                <EuiText size={prefrences.compressedLayout ? 'xs' : 's'}>
                    {t(`alarms:type_${value}`)}
                </EuiText>
            </EuiFlexItem>
            {record.additionalData.currentValue &&
                prefrences?.displayValues && (
                    <EuiFlexItem>
                        <EuiFlexGroup gutterSize="xs" wrap>
                            {record.additionalData.channel && (
                                <EuiFlexItem grow={false}>
                                    <EuiBadge
                                        color={bgPrimaryColor}
                                        title={t('alarms:error_limit')}
                                        iconType="database"
                                    >
                                        {record.additionalData.channel}
                                    </EuiBadge>
                                </EuiFlexItem>
                            )}
                            <EuiFlexItem grow={false}>
                                <EuiBadge
                                    color={bgDefaultColor}
                                    title={t('alarms:current_value')}
                                    iconType="pin"
                                >
                                    {formatValue(
                                        record.additionalData.currentValue
                                    )}
                                </EuiBadge>
                            </EuiFlexItem>
                            {record.additionalData.warningLimit && (
                                <EuiFlexItem grow={false}>
                                    <EuiBadge
                                        color={bgWarningColor}
                                        title={t('alarms:warning_limit')}
                                        iconType="alert"
                                    >
                                        {formatValueRange(
                                            record.additionalData.warningLimit
                                        )}
                                    </EuiBadge>
                                </EuiFlexItem>
                            )}
                            {record.additionalData.errorLimit && (
                                <EuiFlexItem grow={false}>
                                    <EuiBadge
                                        color={bgDangerColor}
                                        title={t('alarms:error_limit')}
                                        iconType="alert"
                                    >
                                        {formatValueRange(
                                            record.additionalData.errorLimit
                                        )}
                                    </EuiBadge>
                                </EuiFlexItem>
                            )}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                )}

            {record.additionalData.exception &&
                prefrences?.displayTechDetails && (
                    <EuiFlexItem>
                        <EuiCode>
                            {prefrences.displayFullTechDetails ? (
                                record.additionalData.exception
                            ) : (
                                <EuiTextTruncate
                                    text={record.additionalData.exception}
                                />
                            )}
                        </EuiCode>
                    </EuiFlexItem>
                )}
        </EuiFlexGroup>
    )
}

export default AlarmTypeCell
