import parse, { DOMNode } from 'html-react-parser'

import sanitizeHtml from 'sanitize-html'

const allowedTags = [
    'div',
    'a',
    'b',
    'big',
    'blockquote',
    'br',
    'code',
    'dd',
    'dl',
    'dt',
    'em',
    'embed',
    'font',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'li',
    'ol',
    'p',
    'param',
    'pre',
    'small',
    'span',
    'strike',
    'strong',
    'sup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'time',
    'tr',
    'u',
    'ul',
    'img',
    's',
]

export type HtmlRenderers = {
    img: (el: JSX.Element) => any
    link: (el: JSX.Element) => any
}

export const sanitizeHtmlContent = (html: string) =>
    sanitizeHtml(html, {
        allowedTags,
        allowedAttributes: {
            img: ['src', 'width', 'height', 'style'],
            a: ['href'],
        },
        allowedSchemes: ['data', 'http', 'https', 'blob'],
    })

/**
 * Render and sanitize HTML string to React components.
 * @note This function is used to render HTML internally. use HtmlContent component instead.
 * @param html HTML string to be rendered
 * @returns React components
 */
export const renderHtml = (html: string, renderers?: HtmlRenderers) => {
    return parse(sanitizeHtmlContent(html), {
        replace: (dom: DOMNode) => {
            if (
                dom.type === 'tag' &&
                (dom as any).name === 'img' &&
                renderers?.img
            ) {
                return renderers?.img(dom as any)
            }

            if (
                dom.type === 'tag' &&
                (dom as any).name === 'a' &&
                renderers?.link
            ) {
                return renderers?.link(dom as any)
            }
        },
    })
}

export const sanitizeAllHtml = (html: string) =>
    sanitizeHtml(html, { allowedTags: [] })

export const isEmptyHtml = (html: string) =>
    !html || html === '<p class="editor-paragraph"><br></p>'

export const isHTMLElement = (el: unknown): el is HTMLElement => {
    return el instanceof HTMLElement
}

export const canUseDOM = () => {
    return (
        typeof window !== 'undefined' &&
        typeof window.document !== 'undefined' &&
        typeof window.document.createElement !== 'undefined'
    )
}
