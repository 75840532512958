import {
    Alarm,
    AlarmsCollectionResult,
    configAlarmsColumns,
} from '@services/alarms'
import { FC, useEffect, useMemo } from 'react'
import { Table, TableProps } from '@components/table'
import { useAppDispatch, useAppSelector } from '@hooks/store'

import { Query } from '@services/api'
import { setAlarmsQuery } from '@store/alarms'
import { useNavigate } from 'react-router-dom'

type AlarmsTableProps = {
    data: AlarmsCollectionResult | undefined
    loading?: boolean
}

const AlarmsTable: FC<AlarmsTableProps> = ({ data, loading }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { prefrences, query } = useAppSelector((state) => state.alarms)

    const columns = useMemo(() => configAlarmsColumns(prefrences), [prefrences])

    const defaultSort = useMemo(() => {
        const sorts = {
            field: 'created',
            direction: 'desc' as TableProps<Alarm>['sortDirection'],
        }

        if (query?.sorts?.length) {
            const sort = query.sorts[0]

            if (sort.indexOf('-') !== -1) {
                sorts.field = sort.substring(1)
                sorts.direction = 'desc'
            } else {
                sorts.field = sort
                sorts.direction = 'asc'
            }
        }

        return sorts
    }, [query])

    const getRowClass = (record: Alarm) => {
        if (record.isAcknowledged || record.isOk) return ''

        return `table__row--${record.status.toLowerCase()}`
    }

    const handleOnQueryChange = (query: Query) =>
        dispatch(setAlarmsQuery({ ...query }))

    useEffect(() => {
        // Reset the page to 1, if there is no longer data on the page number from the query in the store
        if (
            data?.totalCount &&
            data?.totalCount > 0 &&
            query.page &&
            query.pageSize &&
            (query.page - 1) * query.pageSize > data?.totalCount
        )
            dispatch(setAlarmsQuery({ ...query, page: 1 }))
    }, [data])

    return (
        <Table<Alarm>
            items={data?.items || []}
            totalCount={data?.totalCount || 0}
            onChange={handleOnQueryChange}
            onRowClick={(record) => {
                navigate(`/alarms/${record.id}`)
            }}
            rowClass={getRowClass}
            loading={loading}
            columns={columns}
            compressed={prefrences?.compressedLayout}
            layout="auto"
            sortField={defaultSort.field}
            sortDirection={defaultSort.direction}
        />
    )
}

export default AlarmsTable
