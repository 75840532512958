import {
    CSSProperties,
    Ref,
    SyntheticEvent,
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'
import {
    Criteria,
    EuiBasicTable,
    EuiBasicTableColumn,
    EuiButton,
    EuiConfirmModal,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
} from '@elastic/eui'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTS, Query } from '@services/api'
import { TableBooleanColumn, getTableActions } from './Table.renders'

import TableFilters from '../TableFilters/TableFilters'
import { formatFullDate } from '@utils/dates'
import { useTranslation } from 'react-i18next'

type RowProps = {
    style?: CSSProperties
    className?: string
    onClick?: (e: any) => void
}

export type TableColumn = {
    id?: string
    field?: string
    name?: string
    sortable?: boolean
    renderAs?: 'text' | 'boolean' | 'date' | 'action'
    formatter?: (value: any, row: any) => JSX.Element
    alignment?: 'left' | 'center' | 'right'
    filterable?: boolean
    filterField?: string
    filterPlaceholder?: string
    filterValue?: any
    filterType?: 'text' | 'boolean' | 'options' | 'date'
    filterOptions?: any[]
    width?: string
    hidden?: boolean
    customActions?: {
        label: string
        icon: string
        description: string
        onClick: (record: any) => void
    }[]
    onEdit?: (record: any) => void
    onDelete?: (record: any) => void
    isDeleteEnabled?: (record: any) => boolean
    onView?: (record: any) => void
}

export type TableProps<T extends object> = {
    items: T[]
    page?: number
    pageSize?: number
    totalCount: number
    sortField?: string
    sortDirection?: 'asc' | 'desc'
    loading?: boolean
    selectable?: boolean
    layout?: 'fixed' | 'auto'
    customButtons?: JSX.Element[]
    itemSelectable?: (item: T) => boolean
    onSelectionChange?: (selection: T[]) => void
    deleteSelectedRows?: (selection: T[]) => void
    selectAll?: 0 | 1
    deselectAll?: 0 | 1
    columns: TableColumn[]
    compressed?: boolean
    onChange?: (query: Query) => void
    onRowClick?: (record: T, e?: SyntheticEvent) => void
    rowColor?: (record: T) => string
    rowClass?: (record: T) => string
    onFilter?: (filters: string[]) => void
    onFilterClear?: () => void
}

type DeleteConfirmProps = {
    callback: (record: any) => void
    record: any
}

export interface TableRef {
    selectAll: () => void
    deselectAll: () => void
}

/**
 * Maps the columns to the EuiBasicTableColumns.
 * @param cols Columns to render.
 * @returns EuiBasicTableColumns.
 */
const mapColumns = <T extends object>(
    cols: TableColumn[],
    t: any,
    deleteConfirm: (callback: any, record: any) => void
): EuiBasicTableColumn<T>[] => {
    return cols
        .filter((c) => !c.hidden)
        .map((col) => {
            const mapped = {
                id: col.id,
                name: col.name,
                field: col.field ?? undefined,
                sortable: col.sortable,

                alignment: col.renderAs === 'action' ? 'right' : col.alignment,
                width: col.renderAs === 'action' ? '100px' : col.width,
                render: (value: any, record: T) => {
                    if (col.formatter) {
                        return col.formatter(value, record)
                    }

                    switch (col.renderAs) {
                        case 'boolean':
                            return <TableBooleanColumn value={value} />

                        case 'date':
                            return value ? formatFullDate(value) : ''
                        case 'text':
                        default:
                            return <span>{value}</span>
                    }
                },
                actions:
                    col.renderAs !== 'action'
                        ? undefined
                        : getTableActions({
                              editName: t('common:edit'),
                              editDescription: t('common:edit_description'),
                              deleteName: t('common:delete'),
                              deleteDescription: t('common:delete_description'),
                              viewName: t('common:view'),
                              viewDescription: t('common:view_description'),
                              customActions: col.customActions,
                              onView: col.onView,
                              onEdit: col.onEdit,
                              onDelete: col.onDelete
                                  ? (record: any) => {
                                        if (col.onDelete) {
                                            deleteConfirm(col.onDelete, record)
                                        }
                                    }
                                  : undefined,
                              isDeleteEnabled: col.isDeleteEnabled,
                          }),
            }

            return mapped as EuiBasicTableColumn<T>
        })
}

// eslint-disable-next-line react/display-name
const Table = forwardRef(
    <T extends object>(props: TableProps<T>, ref: Ref<TableRef>) => {
        const { t } = useTranslation(['common'])
        const tableRef = useRef<any>()
        const [pageIndex, setPageIndex] = useState<number>(props.page ?? 0)
        const [pageSize, setPageSize] = useState(
            props.pageSize ?? DEFAULT_PAGE_SIZE
        )
        const [sortField, setSortField] = useState<any | undefined>(
            props.sortField ?? props.columns[0].field
        )
        const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
            props.sortDirection ?? 'asc'
        )
        const [deleteConfirm, setDeleteConfirm] = useState<
            DeleteConfirmProps | undefined
        >(undefined)
        const [selectedRows, setSelectedRows] = useState<T[]>([])

        const onTableChange = (criteria: Criteria<T>) => {
            const cPageIndex = criteria.page?.index ?? 0
            const cPageSize = criteria.page?.size ?? DEFAULT_PAGE_SIZE
            const cSortField =
                criteria.sort?.field ??
                props.sortField ??
                props.columns[0].field
            const cSortDirection =
                criteria.sort?.direction ?? props.sortDirection ?? 'asc'

            setPageIndex(cPageIndex)
            setPageSize(cPageSize)
            setSortField(cSortField)
            setSortDirection(cSortDirection)

            const query: Query = {
                page: cPageIndex + 1,
                pageSize: cPageSize,
            }

            if (cSortField) {
                query.sorts = [
                    `${
                        cSortDirection === 'desc' ? '-' : ''
                    }${cSortField.toString()}`,
                ]
            }

            props.onChange?.(query)
        }

        const onDeleteConfirmed = (callback: any, record: any) => {
            setDeleteConfirm({ callback, record })
        }

        const mappedColumns = mapColumns(props.columns, t, onDeleteConfirmed)

        const columnsFilters = useMemo(
            () =>
                props.columns
                    .filter((col) => col.filterable)
                    .map((col) => ({
                        field: col.filterField ?? col.field ?? '',
                        value: col.filterValue ?? '',
                        type: col.filterType ?? 'text',
                        options: col.filterOptions,
                        placeholder: col.filterPlaceholder,
                    })),
            [props.columns]
        )

        const getRowProps = (record: any) => {
            let rowProps: RowProps = {}

            if (props.onRowClick) {
                rowProps = {
                    ...rowProps,
                    onClick: (e) => {
                        if (
                            e.target.type !== 'button' &&
                            e.target.type !== 'a' &&
                            !e.target.closest('button') &&
                            !e.target.closest('a')
                        )
                            props.onRowClick?.(record, e)
                    },
                    style: { cursor: 'pointer' },
                }
            }

            if (props.rowColor) {
                rowProps = {
                    ...rowProps,
                    style: {
                        ...rowProps.style,
                        backgroundColor: props.rowColor(record),
                    },
                }
            }

            if (props.rowClass) {
                rowProps = {
                    ...rowProps,
                    className: props.rowClass(record),
                }
            }

            return rowProps
        }

        const handleOnSelectionChange = (selection: T[]) => {
            setSelectedRows(selection)
            props.onSelectionChange?.(selection)
        }

        // Ref external callbacks/methods
        useImperativeHandle(
            ref,
            () => ({
                selectAll() {
                    setSelectedRows(props.items)
                    props.onSelectionChange?.(props.items)
                },
                deselectAll() {
                    tableRef.current?.setSelection([])
                },
            }),
            [props.onSelectionChange]
        )

        return (
            <>
                {!!deleteConfirm && (
                    <EuiConfirmModal
                        title={
                            Array.isArray(deleteConfirm.record)
                                ? t('delete_confirm_multi_title')
                                : t('common:delete_confirm_title')
                        }
                        onCancel={() => setDeleteConfirm(undefined)}
                        onConfirm={() => {
                            deleteConfirm.callback(deleteConfirm.record)
                            setDeleteConfirm(undefined)
                        }}
                        cancelButtonText={t('common:delete_confirm_cancel')}
                        confirmButtonText={
                            Array.isArray(deleteConfirm.record)
                                ? t('delete_confirm_multi_ok')
                                : t('common:delete_confirm_ok')
                        }
                        buttonColor="danger"
                        defaultFocusedButton="confirm"
                    >
                        <p>
                            {Array.isArray(deleteConfirm.record)
                                ? t('delete_confirm_multi_description', {
                                      rowCount: deleteConfirm.record.length,
                                  })
                                : t('common:delete_confirm_description')}
                        </p>
                    </EuiConfirmModal>
                )}

                <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow>
                        <>
                            {columnsFilters && columnsFilters.length > 0 && (
                                <TableFilters
                                    filters={columnsFilters}
                                    onFilter={props.onFilter}
                                    onClear={props.onFilterClear}
                                />
                            )}
                        </>
                    </EuiFlexItem>
                    {props.selectable && props.deleteSelectedRows && (
                        <EuiFlexItem grow={false} key="deleteSelectedRows">
                            <EuiButton
                                key="btn_new_device"
                                color="accent"
                                iconType="trash"
                                onClick={() =>
                                    onDeleteConfirmed(
                                        props.deleteSelectedRows,
                                        selectedRows
                                    )
                                }
                                disabled={selectedRows.length === 0}
                            >
                                {t('common:delete_selected_rows', {
                                    rowCount: selectedRows.length,
                                })}
                            </EuiButton>
                        </EuiFlexItem>
                    )}
                    {props.customButtons &&
                        props.customButtons.map((b, i) => (
                            <EuiFlexItem grow={false} key={i}>
                                {b}
                            </EuiFlexItem>
                        ))}
                </EuiFlexGroup>
                <EuiSpacer size="l" />

                <EuiBasicTable
                    loading={props.loading}
                    items={props.items}
                    itemId="id"
                    ref={tableRef}
                    columns={mappedColumns}
                    onChange={onTableChange}
                    tableLayout={props.layout}
                    rowProps={getRowProps}
                    selection={
                        props.selectable
                            ? {
                                  selectable: (item: T) =>
                                      props.itemSelectable?.(item) ?? false,
                                  onSelectionChange: handleOnSelectionChange,
                                  selected: selectedRows,
                              }
                            : undefined
                    }
                    compressed={props.compressed}
                    sorting={{
                        sort: {
                            field: sortField,
                            direction: sortDirection,
                        },
                    }}
                    pagination={{
                        pageIndex: pageIndex,
                        pageSize: pageSize,
                        totalItemCount: props.totalCount,
                        pageSizeOptions: DEFAULT_PAGE_SIZE_OPTS,
                        showPerPageOptions: false,
                    }}
                />
            </>
        )
    }
)
export default Table as <T extends object>(
    props: TableProps<T> & { ref?: Ref<TableRef> }
) => JSX.Element
